import axios from "axios";

export default {
  state: {
    dataPartner: {
      isLoad:false,
      language: "ua",
      partner_name: " ",
      allPartners:false,
      partner_phone: [
        {
          phone: " ",
          hasViber: "hasViber"
        },
        {
          phone: "",
          hasViber: "noViber"
        }
      ],
      api: {
        allVakans: "",
        oneVakans: ""
      },
      mail_partner: {
        mail: ""
      },
      addres_partner: {
        adres: " "
      },
      tagline_partner: {
        tagline: "Виза и работа в Польше "
      },
      theme_partner: {
        background: "white",
        cardVakans: "white darken-3 white--text",
        iconVakans: "white darken-4 white--text",
        btnVakans: "white darken-3  white--text",
        header: " darken-4 white-text",
        headerName: " white--text",
        headerBtn: " white--text",
        futerContact: "white white--text",
        futerdown: "white white--text",
        futerCopyrait: "white  white--text"
      },
      heightlogo: "70px",
      widthlogo: "80px",
      swoAllPartnersVakans:false
    },

    FAQPartner: []
  },
  mutations: {
    UPDATE_PARTNER_CONFIG (state, dataPartner) {
      state.dataPartner = dataPartner;
      state.dataPartner.isLoad=true;
    },
    UPDATE_PARTNER_FAQ (state, FAQPartner) {
      state.FAQPartner = FAQPartner;
    },
    UPDATE_LANG (state) {
      // console.log(state.dataPartner.language);
      if (state.dataPartner.language == "ru") {
        state.dataPartner.language = "ua";
      } else if (state.dataPartner.language == "ua") {
        state.dataPartner.language = "ru";
      }
    }
  },
  actions: {
    GET_PARTNER_CONFIG_FROM_API ({ commit }) {
      // return axios("configPartner.php", {
      //  return axios("../config/configPartner.php", {
      return axios("../config/conf-partner.json", {
        method: "GET"
      })
        .then(dataPartner => {
          commit("UPDATE_PARTNER_CONFIG", dataPartner.data);
          return dataPartner.data;
        })
        .catch(error => {
          console.log(error);
          return error;
        });
    },
    GET_PARTNER_FAQ ({ commit }) {
      // return axios("configPartner.php", {
      //  return axios("../config/configPartner.php", {
      // return axios(
      //   "http://partners.site/partners.site/public/config/question.php", {
      return axios("../config/question.php", {
        method: "GET"
      })
        .then(FAQPartner => {
          // console.log(FAQPartner);
          commit("UPDATE_PARTNER_FAQ", FAQPartner.data);
          return FAQPartner.data;
        })
        .catch(error => {
          console.log(error);
          return error;
        });
    },
    CREATE_LID ({ commit }, idquery) {
      // console.log(idquery);
      return (
        axios
          .post("https://api.partners.fenek.fun/V3/API/createLid/", idquery, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          })
          //  .post("/dat/getName.php", str)

          .then(answeCreate => {
            // console.log("answeCreate");

            return answeCreate.data;
          })

          .catch(error => {
            // commit("ERROR", error);
            console.log("error");
            console.log(error);

            return error;
          })
      );
    },
    CHANGE_LANG ({ commit }) {
      commit("UPDATE_LANG");
    }
  },
  getters: {
    PARTNER_CONFIG (state) {
      return state.dataPartner;
    },
    FAQ_PARTNER (state) {
      //   console.log(state.FAQPartner);
      return state.FAQPartner;
    }
  }
};
