<template>
  <v-footer dark padless>
    <v-card class="flex" flat tile>
      <div id="kontakty"></div>
      <!-- id="futer-card-partner-style" -->

      <v-list
        :class="
          `futer-card-partner-style ${PARTNER_CONFIG.theme_partner.futerContact}`
        "
      >
        <!-- телефоны -->
        <div v-for="(phone, i) in shufflePhones" :key="i">
          <v-list-item>
            <v-list-item-action>
              <a
                class="phone-partner"
                :class="`${PARTNER_CONFIG.theme_partner.futerContactText}`"
                :href="`tel:${phone.phone}`"
              >
                <v-icon
                  :class="
                    `futer-icon-partner-style ${PARTNER_CONFIG.theme_partner.futerContactIcon}`
                  "
                  >mdi-phone</v-icon
                >
              </a>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                <a
                  class="phone-partner"
                  :class="`${PARTNER_CONFIG.theme_partner.futerContactText}`"
                  :href="`tel:${phone.phone}`"
                >
                  {{ phone.phone }}
                  <span v-if="phone.name"> - {{ phone.name }}</span>
                </a>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action :class="phone.hasViber">
              <a
                class="phone-partner "
                :class="`${PARTNER_CONFIG.theme_partner.futerContactText}`"
                :href="`viber://chat?number=${phone.phone}`"
              >
                <v-icon
                  :class="
                    `futer-icon-partner-style ${PARTNER_CONFIG.theme_partner.futerContactIcon}`
                  "
                  >mdi-cellphone-message</v-icon
                >
              </a>
            </v-list-item-action>
          </v-list-item>
          <v-divider inset></v-divider>
        </div>

        <!-- /телефоны -->

        <!-- Почта -->
        <div
          v-for="(mail, i) in PARTNER_CONFIG.mail_partner.mail"
          :key="`${i}mail`"
        >
          <v-list-item>
            <v-list-item-action>
              <v-icon
                :class="
                  `futer-icon-partner-style ${PARTNER_CONFIG.theme_partner.futerContactIcon}`
                "
                >mdi-email</v-icon
              >
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title
                :class="`${PARTNER_CONFIG.theme_partner.futerContactText}`"
              >
                <a
                  class="phone-partner "
                  :class="`${PARTNER_CONFIG.theme_partner.futerContactText}`"
                  :href="`mailto:${mail}`"
                >
                  {{ mail }}</a
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <!-- /почта -->

        <v-divider inset></v-divider>
        <!-- Адрес -->
        <div
          v-for="(adres, i) in PARTNER_CONFIG.addres_partner.adres"
          :key="`${i}adres`"
        >
          <v-list-item>
            <!-- <v-list-item v-if="adres[i]"> -->
            <v-list-item-action>
              <v-icon
                :class="
                  `futer-icon-partner-style ${PARTNER_CONFIG.theme_partner.futerContactIcon}`
                "
                >mdi-map-marker</v-icon
              >
            </v-list-item-action>

            <v-list-item-content>
              <!-- <v-list-item-content v-if="PARTNER_CONFIG.addres_partner.adres"> -->
              <h4 :class="`${PARTNER_CONFIG.theme_partner.futerContactText}`">
                {{ adres }}
              </h4>

              <!-- <v-list-item-title :class="`${PARTNER_CONFIG.theme_partner.futerContactText}`">{{PARTNER_CONFIG.addres_partner.adres}}</v-list-item-title> -->
            </v-list-item-content>
          </v-list-item>
        </div>
        <!-- /Адрес -->
      </v-list>

      <div width="100%" class="mx-auto lighten-2">
        <h3 class="mx-auto text-center  ">
          {{ PARTNER_CONFIG.tagline_partner.tagline }}
        </h3>
      </div>
      <v-card-title
        :class="
          `futer-tagline-partner-style mx-auto ${PARTNER_CONFIG.theme_partner.futerdown}`
        "
        width="100%"
      >
        <div width="100%" class="mx-auto text-center">
          <v-btn
            v-for="(social, i) in PARTNER_CONFIG.social"
            :key="i"
            class="mx-4 text-center"
            dark
            icon
          >
            <a class="phone-partner" :href="social.link">
              <v-icon
                :class="`${PARTNER_CONFIG.theme_partner.futerContactIcon}`"
                size="24px"
                >{{ social.icon }}</v-icon
              >
            </a>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text
        :class="
          `py-2  text-center ${PARTNER_CONFIG.theme_partner.futerCopyrait}`
        "
      >
        <strong>{{ PARTNER_CONFIG.partner_name }}</strong> -
        {{ new Date().getFullYear() }}
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["PARTNER_CONFIG"]),

    shufflePhones() {
      let array = this.PARTNER_CONFIG.partner_phone;

      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)); // случайный индекс от 0 до i

        // поменять элементы местами
        // мы используем для этого синтаксис "деструктурирующее присваивание"
        // подробнее о нём - в следующих главах
        // то же самое можно записать как:
        // let t = array[i]; array[i] = array[j]; array[j] = t
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }
  }
};
</script>

<style lang="css" scoped>
.tagline-partner {
  white-space: pre-wrap;
}

.phone-partner {
  text-decoration: none;
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.noViber {
  display: none;
}

/* .futer-card-default-style {
  background-color: teal;
} */
</style>
