<template>
  <v-app>
    <HeaderApp></HeaderApp>
    <v-content :class="`${PARTNER_CONFIG.theme_partner.background}`">
      <!-- <v-content class="yellow  lighten-1"> -->
      <keep-alive>
        <!-- не удаляй кип алив, загрузка конкретаной вакнсии по событию активатед -->
        <!-- max="2" -->
        <!-- <router-view ></router-view> -->
        <!-- <router-view :key="$route.fullPath"></router-view> -->
        <router-view :key="$route.path"></router-view>
      </keep-alive>
    </v-content>
    <FooterApp />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HeaderApp from "@/components/HeaderApp";
import FooterApp from "@/components/FooterApp";

export default {
  name: "App",
  components: { HeaderApp, FooterApp },
  methods: {
    ...mapActions(["GET_PARTNER_CONFIG_FROM_API"])
  },
  computed: {
    ...mapGetters(["PARTNER_CONFIG"])
  },
  created() {
    this.GET_PARTNER_CONFIG_FROM_API();
  }
};
</script>
